import { gql } from '@apollo/client'

export const CORE_USER_FIELDS = gql`
    fragment CoreUserFields on User {
        id
        email
        phone
        firstName
        lastName
        address
        city
        country
        addressLine1
        addressLine2
        postalCode
        state
        logoImage
        timeZoneOffset
        notes
    }
`

export const USER_PAYMENT_FIELDS = gql`
    fragment UserPaymentFields on User {
        paymentMethodId
        cardBrand
        cardExpMonth
        cardExpYear
        cardLastDigits
        firstName
        lastName
    }
`

export const USER_FIELDS = gql`
    ${CORE_USER_FIELDS}
    ${USER_PAYMENT_FIELDS}
    fragment UserFields on User {
        ...CoreUserFields
        ...UserPaymentFields
    }
`
